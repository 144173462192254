import React from 'react';
import FormRegister from '../../components/Forms/Register';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { changeElement, setJoinTelegram } from '../../utils/Common';
import { toggleMenuDropdown, changeToggleMenuDropdown, disableDropdown } from '../../utils/Common';
import { Context } from '../../utils/Translate';
import { isElementInViewport } from '../../utils/Common'
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import number_format from '../../utils/number_format';
import { XOY_ADDRESS } from '../../config';
import Tooltip from '@mui/material/Tooltip';
import UseWindowSize from '../../hooks/useWindowSize';

const TOKEN_QUERY = gql`
query token($id: String!) {
    token(id:$id) {
        id
        derivedETH
        volume
        volumeUSD
    }
}
`;

const ETH_QUERY = gql`
query eth {
    bundle(id:"1"){
        id
        ethPriceUSD
    }
}
`;

const formatMoneyXoy = (token,eth,currency) => {
    if(currency == 'ETH'){
        const derivedETH = Number(token?.derivedETH || 0);
        if(derivedETH == 0){
            const ethPriceUSD = Number(eth?.ethPriceUSD || 0);
            const volume = Number(token?.volume || 0);
            const volumeUSD = Number(token?.volumeUSD || 0);
            const valueUSD = ((volume == 0 || volumeUSD == 0) ? 0 : (volumeUSD / volume));
            return number_format((valueUSD == 0 ? 0 : (valueUSD/ethPriceUSD)),7,'.',',')+ ' '+currency;
        }else{
            return number_format(derivedETH,7,'.',',')+ ' '+currency;
        }
    }else if(currency == 'USD'){
        const derivedETH = Number(token?.derivedETH || 0);
        const ethPriceUSD = Number(eth?.ethPriceUSD || 0);
        if(derivedETH == 0 || ethPriceUSD == 0){        
            const volume = Number(token?.volume || 0);
            const volumeUSD = Number(token?.volumeUSD || 0);
            return '$' + number_format(((volume == 0 || volumeUSD == 0) ? 0 : (volumeUSD / volume)),2,'.',',')+ ' '+currency;
        }else{
            return '$' + number_format((derivedETH * ethPriceUSD),2,'.',',')+ ' '+currency;
        }
    }else{
        return '';
    }
}

const MenuDropdownHeader = () => {
    const { _ } = React.useContext(Context);

    return (
        <div className="c-menu-dropdown__header">
            <div className="row gx-0">
                <div className="col-5">
                    <img className="c-menu-dropdown__logo" src={'/assets/images/xoycoin-logo-green.svg'} alt="Xoycoin" />
                </div>
                <div className="col-7 o-aling-text__center-end">
                    <p className="c-menu-dropdown__close o-font__p-menu-dropdown" onClick={()=>disableDropdown()}>
                        {_['Cerrar']}
                    </p>
                </div>
            </div>
        </div>
    )
}

const PriceXoy = ({timestamp,menu}) => {
    const { _ } = React.useContext(Context);
    const size = UseWindowSize();

    const { loading, error, data: tokenXoy } = useQuery(TOKEN_QUERY,{
        variables:{
            id: XOY_ADDRESS
        }
    });
    const { loading: loadingEth, data: eth } = useQuery(ETH_QUERY);
 
    const [dateQuery, setDateQuery] = React.useState(null);
    const [ currency, setCurrency ] = React.useState('USD');
    const [ open, setOpen ] = React.useState(false);

    React.useEffect(() => {
        if(tokenXoy?.token){
            setDateQuery(new Date(Date.now()-((new Date()).getTimezoneOffset()*60_000)))
        }else{
            setDateQuery(null);
        }
    },[tokenXoy]);

    if(loading || loadingEth){
        return <span>{_['Load']}</span>
    }

    if(error){
        return <></>
    }

    return <div className={`dropdown ${menu == 'desktop' && 'c-header__cont-navigation'} ${menu == 'mobile' && 'c-menu-dropdown__container__submenu'}`}>
            {
                size > 767 ? <>
                    <Tooltip open={open} title={`${dateQuery && timestamp && `${_['HeadConsult']} ${dateQuery.toISOString().replace(/([TZ]|(\.[0-9]{3}))/g,' ')}`}`} >
                        <button 
                            className="c-buttons__menu-dropdown-plane dropdown-toggle l" 
                            id="dropdownMenuButton1" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false" 
                            onMouseOver={() => setTimeout(() => { setOpen(true) }, 200)}
                            onMouseOut={() => setTimeout(() => { setOpen(false) }, 200)}
                            onClick={ () => setOpen(!open) }
                        >
                            {
                                menu == 'mobile' && 
                                    <span>
                                        <div>
                                            1 XOY = {formatMoneyXoy(tokenXoy?.token,eth?.bundle,currency)}
                                        </div>
                                        {dateQuery && timestamp && <div className='PriceXoycoinQuery txt-10'>{_['HeadConsult']} {dateQuery.toISOString().replace(/([TZ]|(\.[0-9]{3}))/g,' ')}</div>}
                                    </span>
                            }
                            {
                                menu == 'desktop' && 
                                    <span>
                                        1 XOY = {formatMoneyXoy(tokenXoy?.token,eth?.bundle,currency)}
                                    </span>
                            }
                        </button>
                    </Tooltip>
                </> : <>
                    <button 
                        className="c-buttons__menu-dropdown-plane dropdown-toggle l" 
                        id="dropdownMenuButton1" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false" 
                        onMouseOver={() => setTimeout(() => { setOpen(true) }, 200)}
                        onMouseOut={() => setTimeout(() => { setOpen(false) }, 200)}
                        onClick={ () => setOpen(!open) }
                    >
                        {
                            menu == 'mobile' && 
                                <span>
                                    <div>
                                        1 XOY = {formatMoneyXoy(tokenXoy?.token,eth?.bundle,currency)}
                                    </div>
                                    {dateQuery && timestamp && <div className='PriceXoycoinQuery txt-10'>{_['HeadConsult']} {dateQuery.toISOString().replace(/([TZ]|(\.[0-9]{3}))/g,' ')}</div>}
                                </span>
                        }
                        {
                            menu == 'desktop' && 
                                <span>
                                    1 XOY = {formatMoneyXoy(tokenXoy?.token,eth?.bundle,currency)}
                                </span>
                        }
                    </button>
                </>
            }
                
            <ul className={`dropdown-menu ${menu == 'desktop' && 'c-header__menu'} ${menu == 'mobile' && 'c-menu-dropdown__menu'}`} aria-labelledby="dropdownMenuButton1">
                <li>
                    <button onClick={()=>setCurrency('USD')} className={`dropdown-item c-buttons__menu-dropdown-border ${currency == 'USD' && 'active'}`} >
                        USD
                    </button>
                </li>
                <li>
                    <button onClick={()=>setCurrency('ETH')} className={`dropdown-item c-buttons__menu-dropdown-border ${currency == 'ETH' && 'active'}`} >
                        ETH
                    </button>
                </li>
            </ul>
        </div>
}

export default function Header() {
    const [ showActive, setShowActive ] = React.useState(0);
    const { _, switchLang } = React.useContext(Context);

    const scrollActive = ()=> {
        window.addEventListener('scroll', () => {
            if (isElementInViewport('id-token-component') ) {
                setShowActive(0)
            } else if (isElementInViewport('id-documents-component') ) {
                setShowActive(1)
            } else if (isElementInViewport('id-proyect-component') ) {
                setShowActive(2)
            } else if (isElementInViewport('id-roadmap-component') ) {
                setShowActive(3)
            } else if (isElementInViewport('__team-component') ) {
                setShowActive(4)
            }
        });
    }

    React.useEffect(() => {
        scrollActive();
    })

    return <>
        <header className="c-header">
            <div className="c-menu-dropdown" style={{backgroundImage: `url("/assets/images/token/arrows-white.png")` }}  id="js-dropdown-menu">
                <MenuDropdownHeader />

                <div className="c-menu-dropdown__container">
                    <nav className="c-menu-dropdown__nav" role="navigation">
                        <ul className="c-menu-dropdown__menu" >
                            <li className="c-menu-dropdown__menu-item">
                                <PriceXoy timestamp={true} menu="mobile" />
                            </li>
                            <li className="c-menu-dropdown__menu-item" onClick={()=>changeToggleMenuDropdown('__best-ally')} >
                                <span className={`c-menu-dropdown__menu-item-link ${showActive == 2 && 'active'}`}>
                                    {_['HeadLink003']}
                                </span>
                            </li>
                            <li className="c-menu-dropdown__menu-item" onClick={()=>changeToggleMenuDropdown('__team-component')} >
                                <span className={`c-menu-dropdown__menu-item-link ${showActive == 4 && 'active'}`}>
                                    {_['HeadLink005']}
                                </span>
                            </li>
                            <li className="c-menu-dropdown__menu-item">
                                <a className="c-menu-dropdown__menu-item-link" href={`/assets/pdf/${_["Route FAQS"]}`} target="_blank" rel="noopener noreferrer" >
                                    {_['HeadLink006']}
                                </a>
                            </li>

                            <li className="c-menu-dropdown__menu-item">
                                <span onClick={()=>switchLang('en')} className={`${_['Actual'] == 'en' ? 'c-menu-dropdown__menu-item-link active' : '' }`}>
                                    En</span> / <span onClick={()=>switchLang('es')} className={`${_['Actual'] == 'es' ? 'c-menu-dropdown__menu-item-link active' : '' }`}>Es
                                </span>
                            </li>
                        </ul>
                    </nav>
                </div>

                {/* <div className="c-buttons c-buttons__menu-dropdown">
                    <a className="c-buttons__menu-dropdown-border" href={`/assets/pdf/${_["RouteLitepaper"]}`} target="_blank" rel="noopener noreferrer" >
                        {_["Litepaper"]}
                    </a>
                    <button className="c-buttons__menu-dropdown-plane" onClick={()=>changeElement('get-ready')} >
                        {_["Preparate"]}
                    </button>
                </div>

                <nav className="c-menu-dropdown__nav" role="navigation">
                    <ul className="c-menu-dropdown__menu" >
                        <li className="c-menu-dropdown__menu-item">
                            <span onClick={()=>switchLang('en')} className={`c-header__menu-item-language ${_['Actual'] == 'en' ? 'c-header__menu-item-is-selected' : '' }`}>En</span> / <span onClick={()=>switchLang('es')} className={`c-header__menu-item-language ${_['Actual'] == 'es' ? 'c-header__menu-item-is-selected' : '' }`}>Es</span>
                        </li>
                    </ul>
                </nav> */}
            </div>

            <div className="c-menu-dropdown c-menu-dropdown--top" style={{backgroundImage: `url("/assets/images/token/arrows-white.png")` }} id="js-dropdown-register">
                <MenuDropdownHeader />
                
                <FormRegister />
            </div>

            <div className="row gx-0 o-column-direction">
                <div className="c-header__cont-solid" id="js-join-telegram">
                    <div className="o-container o-container--telegram">
                        <AnimationOnScroll animateIn="animate__slideInDown" delay={0} offset={0} >
                            <div className="row gx-0">
                                <div className="col-1">
                                    <button className="c-buttons__header-close-telegram" onClick={()=>setJoinTelegram()}>
                                        X
                                    </button>
                                </div>
                                <div className="col-11 col-ml-10 c-header__cont-solid-info">
                                    <img className="c-header__icon-register" src={'/assets/images/telegram-red.svg'} alt="telegram" />
                                    <p className="c-header__text-solid">
                                        <span className="d-none d-md-block">
                                            {_['Entérate de noticias y eventos antes que nadie en nuestro canal de Telegram']}
                                        </span>
                                    </p>
                                    <a className="c-buttons__join-now" href="https://t.me/xoycoinnoticias" target="_blank" rel="noopener noreferrer" >
                                        <span className="d-block d-md-none" dangerouslySetInnerHTML={{__html: _["¡Únete al canal de Telegram aquí!"]}} />
                                        <span className="d-none d-md-block" dangerouslySetInnerHTML={{__html: _['¡Únete ahora!']}} />
                                    </a>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </AnimationOnScroll>
                    </div>
                </div>

                <div className="c-header__cont-transparent">
                    <div className="o-container">
                        <div className="row gx-0">

                            <div className="c-header__div c-header__div--logo">
                                <AnimationOnScroll animateIn="animate__slideInDown" delay={0} offset={0} >
                                    <img className="c-header__logo" src={'/assets/images/xoycoin-logo-white.svg'} alt="Xoycoin" />
                                </AnimationOnScroll>
                            </div>

                            <div className="c-header__div c-header__div--navigation">
                                <div className="c-header__active-dropdown" onClick={()=>toggleMenuDropdown()}>
                                    {_["HeadMenu"]}
                                </div>

                                <div className="c-header__cont-navigation">
                                    <nav className="c-header__nav" role="navigation">
                                        <ul className="c-header__menu">
                                            <li className="c-header__menu-item" onClick={()=>changeElement('__best-ally')}>
                                                <AnimationOnScroll animateIn="animate__slideInDown" delay={300} offset={0} >
                                                    <span className={`o-font__header-link ${showActive == 2 && 'active'}`} id="id-header-proyect">
                                                        {_['HeadLink003']}
                                                    </span>
                                                </AnimationOnScroll>
                                            </li>
                                            <li className="c-header__menu-item" onClick={()=>changeElement('__team-component')}>
                                                <AnimationOnScroll animateIn="animate__slideInDown" delay={400} offset={0} >
                                                    <span className={`o-font__header-link ${showActive == 4 && 'active'}`} id="id-header-team">
                                                        {_['HeadLink005']}
                                                    </span>
                                                </AnimationOnScroll>
                                            </li>
                                            <li className="c-header__menu-item">
                                                <AnimationOnScroll animateIn="animate__slideInDown" delay={500} offset={0} >
                                                    <a className="o-font__header-link c-header__menu-item-link" href={`/assets/pdf/${_["Route FAQS"]}`} target="_blank" rel="noopener noreferrer" >
                                                        {_['HeadLink006']}
                                                    </a>
                                                </AnimationOnScroll>
                                            </li>

                                            <li className="c-header__menu-item pb-0">
                                                <AnimationOnScroll animateIn="animate__slideInDown" delay={600} offset={0} >
                                                    <span onClick={()=>switchLang('en')} className={`${_['Actual'] == 'en' ? 'c-header__menu-item-is-selected' : '' }`}>
                                                        En</span> / <span onClick={()=>switchLang('es')} className={`${_['Actual'] == 'es' ? 'c-header__menu-item-is-selected' : '' }`}>Es
                                                    </span>
                                                </AnimationOnScroll>
                                            </li>

                                            <li className="c-header__menu-item">
                                                <AnimationOnScroll animateIn="animate__slideInDown" delay={700} offset={0} >
                                                    <PriceXoy timestamp={true} menu="desktop" />
                                                </AnimationOnScroll>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
}