import React from 'react';
import { Context } from '../../utils/Translate';
import LastDiv from '../LastDiv';

export default function Token() {
    const { _ } = React.useContext(Context);

    return <>
        <div className="c-token" id="id-token-component">
            <div className="c-token__container o-container">
                <div className="row d-flex justify-content-center">
                    <section className="c-token__section c-token__detail--contract-text" style={{zIndex: 20}}>
                        <div className="col-12 ">
                            <div className="row d-flex flex-column-reverse flex-md-row">
                                <div className="col-12 col-md-5 position-relative text-center text-md-start">
                                    <img className="c-token__img-americoano-futbol img" src={'/assets/images/token/americoano-futbol-xoycoin.svg'} alt="americoano-futbol" />
                                </div>
                                
                                <div className="col-12 col-md-7">
                                    <h2 className="c-token__text c-token__text--title" dangerouslySetInnerHTML={{__html: _["TokenSectionTitle"]}} />
                                    <p className="c-token__text c-token__text--detail" dangerouslySetInnerHTML={{__html: _["TokenSectionDescription"]}} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 c-token__detail c-token__detail--contract">
                            <div className="row gx-0">
                                <div className="col-12 col-md-3">
                                    <h4 className="c-token__text c-token__text--contract-title">{_["TokenSectionDetail001"]}</h4>
                                    <h5 className="c-token__text c-token__text--contract-subtitle">Xoycoin</h5>
                                </div>
                                <div className="col-12 col-md-3 pt-4 pt-md-0">
                                    <h4 className="c-token__text c-token__text--contract-title">{_["TokenSectionDetail002"]}</h4>
                                    <h5 className="c-token__text c-token__text--contract-subtitle">ERC20</h5>
                                </div>
                                <div className="col-12 col-md-3 pt-4 pt-md-0">
                                    <h4 className="c-token__text c-token__text--contract-title">{_["TokenSectionDetail003"]}</h4>
                                    <h5 className="c-token__text c-token__text--contract-subtitle">XOY</h5>
                                </div>
                                <div className="col-12 col-md-3 pt-4 pt-md-0">
                                    <h4 className="c-token__text c-token__text--contract-title">{_["TokenSectionDetail004"]}</h4>
                                    <h5 className="c-token__text c-token__text--contract-subtitle">711,130,684</h5>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <LastDiv />
    </>
}