import React from 'react';
import { Form, Col } from 'react-bootstrap';
import axios from 'axios';
import { Context } from '../../utils/Translate';

export default function Register() {
    const [ name, setName ] = React.useState('');
    const [ email, setEmail ] = React.useState('');
    const [ error, setError ] = React.useState(null);
    const [ success, setSuccess ] = React.useState(null);
    const { _ } = React.useContext(Context);

    const handleSubmit = (e)=>{
        e.preventDefault();
        setSuccess(null);
        setError(null);

        let isValidName = /^[A-Za-záéíóúÁÉÍÓÚñÑ\s]{1,50}$/g;
        let isValidEmail = /^\w+([\-\.]\w+)*@\w+([\-\.]\w+)*\.\w+([\-\.]\w+)*$/g;

        if(!name || !isValidName.test(name)){
            return setError(_["Ingrese un nombre válido"]);
        }
        if(!email || !isValidEmail.test(email)){
            return setError(_["Ingrese un email válido"]);
        }

        const data = {
            name,
            email
        }

        axios({
            method:"POST",
            url:"https://api2.xoycoin.io/registro",
            data
        }).then(()=>{
            setSuccess(true)
        }).catch(
            e=>setError(e.message)
        )
    }

    React.useEffect(()=>{
        return function cleanup(){
            setError(null);
            setSuccess(null)
        }
    }, []);

    return (
        <div className="c-register">
            <h1 className="c-register__text-title" dangerouslySetInnerHTML={{__html: _["Tu puedes ser parte del éxito"]}} />
            <h4 className="c-register__text-subtitle" dangerouslySetInnerHTML={{__html: _["¡Únete a Xoycoin hoy mismo!"]}} />

            <Form className="c-register__form">
                <Form.Row className="row">
                    <Form.Group as={Col} md="6" >
                        <Form.Control 
                            value={name}
                            onChange={(e)=>setName(e.target.value)}
                            required 
                            type="text" 
                            className="form-control c-register__control" 
                            placeholder={_["Ingresa tu nombre"]} 
                            maxLength="100"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="6" >
                        <Form.Control 
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                            required 
                            type="email" 
                            className="form-control c-register__control" 
                            placeholder={_["E ingresa tu correo"]} 
                            maxLength="100"
                        />
                    </Form.Group>
                </Form.Row>
                
                <Form.Group as={Col} md="12" className="text-center" >
                    <button  className="c-buttons__register" onClick={handleSubmit}>
                        {_["Regístrate"]}
                    </button>
                    
                    {
                        success &&  
                            <p className="c-register__notification">
                                <strong className="c-register__notification-succes">
                                    {_["Bienvenido a la comunidad Xoycoin"]}
                                </strong>
                            </p>
                    }

                    {
                        error && 
                            <p className="c-register__notification">
                                <strong className="c-register__notification-error">{error}</strong>
                            </p>
                    }
                </Form.Group>
            </Form>
        </div>
    )
}