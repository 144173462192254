import React from 'react';
import { Context } from '../../utils/Translate';
import { changeElement } from '../../utils/Common';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { FacebookPixelContext } from '../../hocs/FacebookPixel';

export default function Challenging() {
    const { _ } = React.useContext(Context);
    const { sendToApi } = React.useContext(FacebookPixelContext);


    const onClickAdqueireXoy =async()=>{
        await sendToApi("Adquiere Xoycoins",{ url: window.location.pathname });
        changeElement('__steps');
    }

    return <>
        <div className="c-challenging overflow-hidden" id="id-challenging-component" style={{backgroundImage: 'url(assets/images/challenging/bg-spiral.svg)'}}>
            <div className="c-challenging__container o-container">
                <div className="row gx-0 o-column-direction">
                    <div className="col-12">
                        <AnimationOnScroll animateIn="animate__fadeInDown" delay={0} animateOnce={true} >
                            <h1 className="c-challenging__text c-challenging__text--title mt-4 mt-md-0" dangerouslySetInnerHTML={{__html: _["ChallengingTitle"]}} />
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInDown" delay={800} animateOnce={true} >
                            <p className="c-challenging__text c-challenging__text--description text-center" dangerouslySetInnerHTML={{__html: _["ChallengingDescription"]}} />
                        </AnimationOnScroll>
                        <div className="c-buttons c-buttons--lineal-buttons">
                            <AnimationOnScroll animateIn="animate__fadeInDown" delay={1200} animateOnce={true} >
                                <button className="c-buttons__challenging" onClick={onClickAdqueireXoy} >
                                    {_["ChallengingButton"]}
                                </button>
                            </AnimationOnScroll>
                        </div>

                        <div className="c-challenging__div-icons text-center row">
                            <div className="col-12 col-sm-6 col-lg-3 ">
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay={1400} animateOnce={true} >
                                    <p className="c-challenging__text c-challenging__text--info-icons" dangerouslySetInnerHTML={{__html: _["ChallengingText001"]}} />
                                    <img className="c-challenging__icon" src="/assets/images/challenging/icon-protection.svg" alt="Protection" />
                                </AnimationOnScroll>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay={1500} animateOnce={true} >
                                    <p className="c-challenging__text c-challenging__text--info-icons" dangerouslySetInnerHTML={{__html: _["ChallengingText002"]}} />
                                    <img className="c-challenging__icon" src="/assets/images/challenging/icon-coverage.svg" alt="coverage" />
                                </AnimationOnScroll>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay={1600} animateOnce={true} >
                                    <p className="c-challenging__text c-challenging__text--info-icons" dangerouslySetInnerHTML={{__html: _["ChallengingText003"]}} />
                                    <img className="c-challenging__icon" src="/assets/images/challenging/icon-deposits.svg" alt="Deposits" />
                                </AnimationOnScroll>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay={1700} animateOnce={true} >
                                    <p className="c-challenging__text c-challenging__text--info-icons" dangerouslySetInnerHTML={{__html: _["ChallengingText004"]}} />
                                    <img className="c-challenging__icon" src="/assets/images/challenging/icon-bets.svg" alt="Bets" />
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}