import React from 'react';
import { changeElement } from '../../utils/Common';
import { Context } from '../../utils/Translate';
import axios from "axios";
import { FacebookPixelContext } from '../../hocs/FacebookPixel';

export default function Footer() {
    const { _ }  = React.useContext(Context)
    const [email,setEmail] = React.useState('');
    const [error,setError] = React.useState(null)
    const [success,setSuccess] = React.useState(null)
    const { sendToApi } = React.useContext(FacebookPixelContext)
    const handleSubmit = (e)=>{
        e.preventDefault();
        setSuccess(null);
        setError(null);

        let isValidEmail = /^\w+([\-\.]\w+)*@\w+([\-\.]\w+)*\.\w+([\-\.]\w+)*$/g;

        if(!email || !isValidEmail.test(email)){
            return setError(_["Ingrese un email válido"])
        }

        const data = {
            email
        }

        axios({
            method:"POST",
            url:"https://api2.xoycoin.io/suscripcion",
            data:data
        }).then(()=>{
            setSuccess(true);
            onSubmit();
        }).catch(
            e=>setError(e.message)
        )
    }

    const onInputEnter = async()=>{
        await sendToApi("Ingresar Correo",{ url: window.location.pathname });
    }
    const onSubmit = async()=>{
        await sendToApi("Correo Registrado",{ url: window.location.pathname });
    }
    return (
        <footer className="c-footer">
            <div className="c-footer__container o-container">
                <div className="row gx-0 o-container__major-z-index">
                    <div className="col-12 col-lg-5 c-footer__div-links">
                        <div className="row no-gutters">
                            <div className='col-12 col-sm-3 col-lg-3 pe-1 text-center text-sm-start' style={{'paddingLeft': 0}}>
                                <img className="c-footer__logo" src={'/assets/images/logo-xoycoin.svg'} alt="Xoycoin" />

                                <p className="c-footer__text" style={{color: 'white'}}>
                                    &copy; Xoycoin 2021
                                </p>
                            </div>

                            <div className='col-12 col-sm-4 col-lg-3'>
                                <nav className="c-footer__nav text-center text-sm-start pt-4 pt-sm-0" role="navigation">
                                    <h5 className="c-footer__text c-footer__text--nav-title">
                                        {_["FooterSectionTitle001"]}
                                    </h5>

                                    <ul className="c-footer__menu">
                                        <li className="c-footer__menu-item">
                                            <span className="c-footer__text c-footer__menu-item-link" onClick={()=>changeElement('__team-component')}>
                                                {_['Equipo']}
                                            </span>
                                        </li>
                                        <li className="c-footer__menu-item">
                                            <a className="c-footer__text c-footer__menu-item-link" href={`/assets/pdf/${_["RouteLitepaper"]}`} target="_blank" rel="noopener noreferrer" >
                                                {_['Litepaper']}
                                            </a>
                                        </li>
                                        <li className="c-footer__menu-item">
                                            <a className="c-footer__text c-footer__menu-item-link" href={`/assets/pdf/${_["Route Whitepaper"]}`} target="_blank" rel="noopener noreferrer" >
                                                {_['Whitepaper']}
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className='col-12 col-sm-4 col-lg-6'>
                                <nav className="c-footer__nav text-center text-sm-start pt-4 pt-sm-0" role="navigation">
                                    <h5 className="c-footer__text c-footer__text--nav-title">
                                        {_["FooterSectionTitle002"]}
                                    </h5>

                                    <ul className="c-footer__menu">
                                        <li className="c-footer__menu-item">
                                            <a className="c-footer__text c-footer__menu-item-link" href={`/assets/pdf/${_["Route FAQS"]}`} target="_blank" rel="noopener noreferrer" >
                                                {_["Preguntas frecuentes"]}
                                            </a>
                                        </li>
                                        <li className="c-footer__menu-item">
                                            <a className="c-footer__text c-footer__menu-item-link" href={`/assets/pdf/${_["Route Conditions"]}`} target="_blank" rel="noopener noreferrer" >
                                                {_["Términos y condiciones"]}
                                            </a>
                                        </li>
                                        <li className="c-footer__menu-item">
                                            <a className="c-footer__text c-footer__menu-item-link" href={`/assets/pdf/${_["Route Policies"]}`} target="_blank" rel="noopener noreferrer" >
                                                {_["Políticas de privacidad"]}
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-7 c-footer__div-social-form">
                        <div className="row no-gutters">
                            <div className='col-12 col-md-5 text-center text-md-start'>
                                <h5 className="c-footer__text c-footer__text--nav-title">
                                    {_["FooterSectionTitle003"]}
                                </h5>

                                <div className='d-flex justify-content-center justify-content-md-start'>
                                    <a className='c-footer__social-div' href="https://www.facebook.com/XoycoinToken" target="_blank" rel="noopener noreferrer" >
                                        <img className="c-footer__logo c-footer__logo--social-media" src={'/assets/images/footer/logo-facebook.svg'} alt="Xoycoin" />
                                    </a>
                                    <a className='c-footer__social-div' href="https://t.me/xoycoinnoticias" target="_blank" rel="noopener noreferrer" >
                                        <img className="c-footer__logo c-footer__logo--social-media" src={'/assets/images/footer/logo-telegram.svg'} alt="Xoycoin" />
                                    </a>
                                    <a className='c-footer__social-div' href="https://www.instagram.com/xoycoin_token/" target="_blank" rel="noopener noreferrer" >
                                        <img className="c-footer__logo c-footer__logo--social-media" src={'/assets/images/footer/logo-instagram.svg'} alt="Xoycoin" />
                                    </a>
                                    <a className='c-footer__social-div' href="https://www.youtube.com/channel/UCnXQ0tXBkMFr3YR6r2qQq6A" target="_blank" rel="noopener noreferrer" >
                                        <img className="c-footer__logo c-footer__logo--social-media" src={'/assets/images/footer/logo-youtube.svg'} alt="Xoycoin" />
                                    </a>
                                </div>
                                
                                <h5 className="c-footer__text c-footer__text--nav-legend">{_["UniswapLegend"]}</h5>
                            </div>
                            <div className='col-12 col-md-7 pt-4 pt-md-0'>
                                <p className="c-footer__text" dangerouslySetInnerHTML={{ __html: _["VideosSectionText001"] }} />

                                <form className="c-footer__form-suscription">
                                    <input
                                        onClick={onInputEnter}
                                        type="email" 
                                        className="form-control c-footer__form-control o-font__form-control text-start" 
                                        placeholder={_["Ingresa tu correo"]} 
                                        name="email" 
                                        id="email" 
                                        value={email} 
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />

                                    <button className="c-buttons__footer-receive" onClick={handleSubmit}>
                                        <img className="c-footer__form-img-send" src={'/assets/images/btn-send.svg'} alt="send" />
                                    </button>
                                </form>

                                <div className="row">
                                    <div className='col-5'>
                                        <a className="c-footer__text c-footer__menu-item-link" href={`mailto:${_["contacto@xoycoin.io"]}`}>
                                            {_["contacto@xoycoin.io"]}
                                        </a>
                                    </div>
                                    <div className='col-7 text-center'>
                                        
                                        {
                                            success &&  
                                                <p className="c-footer__text c-footer__text--alert-ok">
                                                    {_["Registro exitoso"]}
                                                </p>
                                        }

                                        {
                                            error && 
                                                <p className="c-footer__text c-footer__text--alert-error">
                                                    {error}
                                                </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}