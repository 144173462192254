import React from 'react';  
import OwlCarousel from 'react-owl-carousel';

export default function OwlVideos() {
    const state = {
        dots:false,
        responsive:{
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1200: {
                items: 3
            },
        }
    }

    const itemsVideo = [
        {
            keyYoutube: 'iNRMJBfPbXo',
            imageBG: 'video-01.png'
        },{
            keyYoutube: '066tLzWH6n0',
            imageBG: 'video-02.png'
        },{
            keyYoutube: '7M5MN8w3Yh8',
            imageBG: 'video-03.png'
        },{
            keyYoutube: 'iNRMJBfPbXo',
            imageBG: 'video-01.png'
        },{
            keyYoutube: '066tLzWH6n0',
            imageBG: 'video-02.png'
        },{
            keyYoutube: '7M5MN8w3Yh8',
            imageBG: 'video-03.png'
        }
    ] 

    return (  
        <div className="c-owl-carousel">
            <div className='container-fluid c-owl-carousel--padding'>
                <OwlCarousel className="owl-theme" loop nav {...state} >
                    {
                        itemsVideo.map((iv, index) => {
                            return <a key={index} className='c-owl-carousel__item' href={`https://www.youtube.com/watch?v=${iv.keyYoutube}`} target="_blank" rel="noopener noreferrer">
                                <img className="img img-video" src= {`/assets/images/videos/${iv.imageBG}`} alt="" />
                            </a>
                        })
                    }
                </OwlCarousel>  
            </div>  
        </div>
    )
}