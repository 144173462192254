import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AppLayout from './layout';
import { Context, getTranslator } from './utils/Translate';
// import logo from './logo.svg';
import FacebookPixel from './hocs/FacebookPixel';
import './App.scss';

// Pages
import Home from './pages/Home';
import Faqs from './pages/Faqs';

// Compnenst
import ScrollToTop from './components/ScrollToTop';

// API Uniswap
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloProvider, ApolloClient } from '@apollo/react-hooks';

export const client = new ApolloClient({
    link: new HttpLink({
      uri: "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3",
    }),
    cache: new InMemoryCache()
});

function App() {
    const [language, setLanguage] = React.useState('es');

    React.useEffect(() => {
        //Google Analitycs
		if (window.gtag) {
			window.gtag('config', 'UA-205236050-1');
		}
    }, [])

    return (
        <ApolloProvider client={client}>
        <FacebookPixel>
            <Context.Provider value={{_:getTranslator(language),switchLang:setLanguage, language}} >
                <AppLayout>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path="/faqs" component={Faqs} />
                        </Switch>
                    </ScrollToTop>
                </AppLayout>
            </Context.Provider>
        </FacebookPixel>
        </ApolloProvider>
    );
}

export default App;
