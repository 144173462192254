export function changeElement (event) {
    if (window.gtag) {
        window.gtag('event', 'view_section', {
            'category': 'Ir a una seccion',
            'label': event
        });
    }

    setTimeout(() => {
        let topOfElement = document.querySelector(`#${event}`).offsetTop ;
        window.scroll({ top: topOfElement , behavior: "smooth" });
    }, 100);
}

export function toggleMenuDropdown() {
    document.getElementById('js-dropdown-menu') && document.getElementById('js-dropdown-menu').classList.toggle("is-active");
}

export function changeToggleMenuDropdown(event) {
    if (window.gtag) {
        window.gtag('event', 'view_section', {
            'category': 'Ir a una seccion',
            'label': event
        });
    }

    document.getElementById('js-dropdown-menu') && document.getElementById('js-dropdown-menu').classList.toggle("is-active");
    setTimeout(() => {
        let topOfElement = document.querySelector(`#${event}`).offsetTop ;
        window.scroll({ top: topOfElement - 92, behavior: "smooth" });
    }, 100);
}

export function disableDropdown() {
    document.getElementById('js-dropdown-menu') && document.getElementById('js-dropdown-menu').classList.remove("is-active");
    document.getElementById('js-dropdown-register') && document.getElementById('js-dropdown-register').classList.remove("is-active");
}

export function getJoinTelegram() {
    return localStorage.getItem('join_telegram') || false;
}

export function setJoinTelegram() {
    localStorage.setItem('join_telegram', true);

    document.getElementById('js-join-telegram') && document.getElementById('js-join-telegram').classList.add("d-none");
}


export function isElementInViewport(id) {
    var el = document.getElementById(id);

    if (el) {
        var rect = el.getBoundingClientRect();

        return (rect.bottom -137) > 0 &&
            rect.right > 0 &&
            rect.left < (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */ &&
            rect.top < (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */;
    }
}