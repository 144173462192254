import React from 'react';
import { Context } from '../../utils/Translate';
import UseWindowSize from '../../hooks/useWindowSize';
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function Team() {
    const size = UseWindowSize();
    const { _ } = React.useContext(Context);

    return (
        <div className="c-team" id="__team-component" style={{backgroundImage: 'url(assets/images/challenging/bg-spiral.svg)'}}>
            <div className="c-team__container o-container">
                <div className="row">
                    <h1 className="c-team__text c-team__text--title" dangerouslySetInnerHTML={{ __html: _["TeamSectionTitle"] }} />

                    <div className="col-12 col-md-3 text-center">
                        <img className="c-team__img" src={`/assets/images/team/carlos-${size > 767 ? 'web' : 'mobile'}.svg`} alt="Carlos" />

                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} >
                            <p className="c-team__text c-team__text--name">Carlos Lazo</p>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} >
                            <p className="c-team__text c-team__text--tag">CEO</p>
                        </AnimationOnScroll>
                    </div>
                    <div className="col-12 col-md-3 text-center">
                        <img className="c-team__img" src={`/assets/images/team/edgar-${size > 767 ? 'web' : 'mobile'}.svg`} alt="Edgar" />

                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} >
                            <p className="c-team__text c-team__text--name">Edgar Baéz</p>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} >
                            <p className="c-team__text c-team__text--tag">CCO</p>
                        </AnimationOnScroll>
                    </div>
                    <div className="col-12 col-md-3 text-center">
                        <img className="c-team__img" src={`/assets/images/team/nicole-${size > 767 ? 'web' : 'mobile'}.svg`} alt="Nicole" />

                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} >
                            <p className="c-team__text c-team__text--name">Nicole Lazo</p>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} >
                            <p className="c-team__text c-team__text--tag">CMO</p>
                        </AnimationOnScroll>
                    </div>
                    <div className="col-12 col-md-3 text-center">
                        <img className="c-team__img" src={`/assets/images/team/miguel-${size > 767 ? 'web' : 'mobile'}.svg`} alt="Miguel" />

                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} >
                            <p className="c-team__text c-team__text--name">Miguel Sánchez</p>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} >
                            <p className="c-team__text c-team__text--tag">CTO</p>
                        </AnimationOnScroll>
                    </div>
                </div>
            </div>
        </div>
    )
}