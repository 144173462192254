import React, { useState } from 'react';
import { Context } from '../../utils/Translate';
import LastDiv from '../LastDiv';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { FacebookPixelContext } from '../../hocs/FacebookPixel';

const BestAlly = () => {
    const { _ } = React.useContext(Context);

    const { sendToApi } = React.useContext(FacebookPixelContext);
    const goToUpick =async()=>{
        await sendToApi("Boton ir a Upick",{ url: window.location.pathname });
        window.open("https://sports.upick.mx","_blank");
    }

    return <>
        <section id="__best-ally" className="c-best-ally">
            <div className="c-best-ally__container o-container">
                <div className="row">
                    <div className="col-12">
                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} >
                            <h1 className="c-best-ally__text c-best-ally__text--title text-center" dangerouslySetInnerHTML={{ __html: _["BestAllySectionTitle"] }} />
                        </AnimationOnScroll>

                        <div className="text-center c-best-ally__div c-best-ally__div--paragraph">
                            <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} >
                                <p className="c-best-ally__text" dangerouslySetInnerHTML={{ __html: _["BestAllySectionParagraph001"] }} />
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} >
                                <p className="c-best-ally__text" dangerouslySetInnerHTML={{ __html: _["BestAllySectionParagraph002"] }} />
                            </AnimationOnScroll>
                        </div>

                        <div className="text-center">
                            <img className="c-best-ally__img" src="/assets/images/best-ally/construction.svg" alt="Protection" />
                        </div>
                    </div>

                    <div className="d-flex justify-content-center">
                        <AnimationOnScroll animateIn="animate__flipInY" delay={0} animateOnce={true} >
                            <span onClick={goToUpick} className="c-best-ally__btn">
                                UPICK
                            </span>
                        </AnimationOnScroll>
                    </div>
                </div>
            </div>
        </section>
        <LastDiv />
    </>
}

export default BestAlly;