import { createContext, useEffect } from "react"
import { FB_PIXEL_ID, TRACK_API_URI } from "../config";
export const FacebookPixelContext = createContext();

export default function FacebookPixel({ children }){


   
    const sendToApi = async (eventName,data={}) => {

        if(!FB_PIXEL_ID){
            return;
        }

        let fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]);
        let fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]);
        fbp = (fbp.length && fbp[0]) || null;
        fbc = (fbc.length && fbc[0]) || null;
        if(!fbc && window.location.search.includes('fbclid=')){
            fbc = `fb.1.${Date.now()}.${window.location.search.split('fbclid=')[1]}`;
        }
        const reqBody = {
            eventName,
            path: data.url,
            source: "website",
            userAgent: window.navigator.userAgent,
        }

        if(fbp){
            reqBody.fbp = fbp; 
        }
        if(fbc){
            reqBody.fbc = fbc; 
        }
        if(data.payload){
            reqBody.customData = data.payload;
        }

        try{
            fetch(`${TRACK_API_URI}/pixel`,{
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(reqBody)
            })
            .then(response => response.json())
            .then((res)=>{
                console.log(res);
            })
            .catch((e)=>{
                console.log(e);
            })
          
        }catch(e){
            console.log(e)
        }
    }

    useEffect(() => {
        sendToApi("PageView",{ url: window.location.pathname });
    }, []);

    return <FacebookPixelContext.Provider value={{ sendToApi }}>
        {children}
    </FacebookPixelContext.Provider>
}