import React from 'react';
import Header from './header';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "animate.css/animate.min.css";

export default function Layout(props) {
    return (
        <>
            <Header />
            
            {
                props.children
            }
        </>
    );
}