import React from 'react';
import { Context } from '../../utils/Translate';
import OwlCarousel from 'react-owl-carousel';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const OurCommunity = () => {
    const { _ } = React.useContext(Context);

    const state = {
        dots: false,
        responsive: {
            0: {
                items: 2
            },
            576: {
                items: 3
            },
            768: {
                items: 4
            },
            1200: {
                items: 5
            },
        }
    }

    const items = [
        {
            name: 'Upick',
            urlImage: 'upick.svg',
            linkPage: 'https://sports.upick.mx/'
        },
        {
            name: 'upickdata',
            urlImage: 'upickdata.svg',
            linkPage: 'https://upickdata.com/'
        },
        {
            name: 'Generales',
            urlImage: 'generales.svg',
            linkPage: 'https://es-la.facebook.com/GeneralesdDgo/'
        },
        {
            name: 'Rojos',
            urlImage: 'rojos.svg',
            linkPage: 'https://www.facebook.com/RojosCDMX1/'
        },
        {
            name: 'Libertadores',
            urlImage: 'libertadores.svg',
            linkPage: 'https://es-la.facebook.com/libertadoresqro/'
        }
    ]

    return (
        <section id="__our-communty" className="c-our-community">
            <div className="c-challenging__container o-container">
                <div className="row">
                    <AnimationOnScroll animateIn="animate__fadeInDown" delay={0} animateOnce={true} >
                        <h1 className="c-our-community__text c-our-community__text--title" dangerouslySetInnerHTML={{__html: _["OurCommunityTitle"]}} />
                    </AnimationOnScroll>
                    <div className='d-flex justify-content-center'>
                        <div className="col-12">
                            <div className="c-owl-carousel owl-holding">
                                <AnimationOnScroll animateIn="animate__fadeInDown" delay={0} animateOnce={true} >
                                    <div className="c-owl-carousel--padding">
                                        <OwlCarousel items={3} className="owl-theme" loop nav {...state} >
                                            {
                                                items.map((item, index) => {
                                                    return <div key={index} >
                                                        <a className='c-our-community__item' target="_blank" rel="noopener noreferrer" href={item.linkPage}>
                                                            <img className="c-our-community__img" src={`assets/images/our-community/${item.urlImage}`} alt={`${item.name}`} />
                                                        </a>
                                                    </div>
                                                })
                                            }
                                        </OwlCarousel>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurCommunity;