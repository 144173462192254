import { createContext } from 'react';
import EN from './messages/en.json';
import ES from './messages/es.json';

const languages = {
    en:EN,
    es:ES
};

const language_default = "es";
const locale='';

export function getTranslator(lang = '') {
    var language='';
    if (lang === '') {
        language = language_default;
    }else{
        language = lang;
    }

    
    Object.keys(languages).forEach((l)=>{
        if(l.includes(language)){
            language = l;
        }
    });

    //echo "Language: language";//exit;
    /**
     * We are using JSON based files for storing translations. 
     * You will need to check if the file exists! 
     * 
     */
    var translations = languages[language];
    
    return translations;
}

export function getLocale() {
    return locale;
}

export const Context = createContext();