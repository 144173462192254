import React from 'react';
import Footer from '../../layout/footer';

// Import of sections that make up this section
import Challenging from '../../components/Challenging';
import OurCommunity from '../../components/OurCommunity'
import Token from '../../components/Token';
import Steps from '../../components/Steps';
import TutorialToken from '../../components/TutorialToken';
import BestAlly from '../../components/BestAlly';
import Team from '../../components/Team';
import Videos from '../../components/Videos';

export default function Home() {

    return (
        <>
            <div className="home-section" >
                <Challenging />
                <OurCommunity />
                <Token />
                <Steps />
                <TutorialToken />
                <BestAlly />
                <Team /> 
                <Videos />
                <Footer />
                
                <div className="c-whatsapp__position">
                    <div className="o-container">
                        <a className="btn c-whatsapp__link" href="https://wa.me/+523335079527" target="_blank" >
                            <img className="c-whatsapp__icon" src={'assets/images/whatsapp.svg'} />
                        </a>
                    </div>
                </div>
            </div>

            <div className="not-supported">
                <p className="text__not-supported">
                    Para mejorar tu experiencia, ingresa a través de una computadora de escritorio.
                </p>
            </div>
        </>
    );
}