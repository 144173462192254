import React from 'react';
import { Context } from '../../utils/Translate';

import OwlVideos from '../OwlVideos';

export default function Videos() {
    const { _ } = React.useContext(Context);

    return (
        <div className="c-videos" id="id-videos-component">
            <div className="c-videos__container o-container">
                <div className="row">
                    <h1 className="c-videos__text c-videos__text--title" dangerouslySetInnerHTML={{ __html: _["VideosSectionTitle"] }} />

                    <OwlVideos />
                </div>
            </div>
        </div>
    )
}