import React from 'react';
import { Context } from '../../utils/Translate';
import LastDiv from '../LastDiv';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { FacebookPixelContext } from '../../hocs/FacebookPixel';

export default function Steps() {
    const { _, language } = React.useContext(Context);

    const { sendToApi } = React.useContext(FacebookPixelContext);
    const handleVerTutorial = async () => {
        await sendToApi("Ver Tutorial", { url: window.location.pathname });
        window.open("https://www.youtube.com/watch?v=P5k0QwxGBx0&t=212s", "_blank");
    }

    return <>
        <section className="c-steps" id="__steps">
            <div className="c-steps__container o-container">
                <div className="row">
                    <h1 className="c-steps__text c-steps__text--title" dangerouslySetInnerHTML={{ __html: _["StepsSectionTitle"] }} />

                    <div className="col-12 col-lg-6 c-steps__div">
                        <div className="block">
                            <ul className="c-steps__ul">
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} className="c-steps__li-pb" >
                                    <li className="c-steps__li">
                                        <p className="c-steps__text c-steps__text--point" >
                                            {_["StepsSectionStep001"]} <span> Metamask </span>
                                            &nbsp;
                                            {
                                                language == "es" && <label style={{ textDecoration: "underline", cursor:"pointer" }} onClick={handleVerTutorial}> ve el tutorial aquí</label>
                                            }
                                        </p>
                                    </li>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} className="c-steps__li-pb" >
                                    <li className="c-steps__li">
                                        <p className="c-steps__text c-steps__text--point" dangerouslySetInnerHTML={{ __html: _["StepsSectionStep002"] }} />
                                    </li>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} className="c-steps__li-pb" >
                                    <li className="c-steps__li">
                                        <p className="c-steps__text c-steps__text--point" dangerouslySetInnerHTML={{ __html: _["StepsSectionStep003"] }} />
                                    </li>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true} className="c-steps__li-pb" >
                                    <li className="c-steps__li">
                                        <p className="c-steps__text c-steps__text--point" dangerouslySetInnerHTML={{ __html: _["StepsSectionStep004"] }} />
                                    </li>
                                </AnimationOnScroll>
                            </ul>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 c-steps__div">
                        <img className="c-steps__img" src={'/assets/images/steps/fox-xoycoin.svg'} alt="Xoycoin" />
                    </div>

                    <div className="col-12 text-center c-steps__div--ok-image">
                        <AnimationOnScroll animateIn="animate__flipInY" delay={0} animateOnce={true} duration={2}>
                            <img className="c-steps__img c-steps__img--ok" src={'/assets/images/steps/ok-image.svg'} alt="Xoycoin" />
                        </AnimationOnScroll>
                    </div>

                    <div className="col-12 text-center">
                        <AnimationOnScroll animateIn="animate__fadeInUp" delay={0} animateOnce={true}  >
                            <p className="c-steps__text c-steps__text--ok" dangerouslySetInnerHTML={{ __html: _["StepsSectionText"] }} />
                        </AnimationOnScroll>
                    </div>
                </div>
            </div>
        </section>
        <LastDiv />
    </>
}