import React, { useState } from 'react';
import { Context } from '../../utils/Translate';
import LastDiv from '../LastDiv';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { FacebookPixelContext } from '../../hocs/FacebookPixel';

const TutorialToken = () => {
    const { _ } = React.useContext(Context);

    const [copy, setCpoy] = useState(false);
    const { sendToApi } = React.useContext(FacebookPixelContext);
    const copyClipboard = async() => {
        await sendToApi("Copiar Direccion XOY",{ url: window.location.pathname });


        if (copy) return
        var copyText = document.getElementById("adress");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        setCpoy(true)
        setTimeout(() => {
            setCpoy(false)
        }, 3000);
    }

    return <>
        <section className="c-tutorial-token" id="__tutorial-token" >
            <div className="c-tutorial-token__container o-container">
                <div className="row">
                    <div className="col-12">
                        <div className="c-tutorial-token__grid">
                            <div className="c-tutorial-token__block c-tutorial-token__block--first">
                                <h1 className="c-tutorial-token__text c-tutorial-token__text--title" dangerouslySetInnerHTML={{ __html: _["TutorialSectionTitle"] }} />

                                <div className='c-tutorial-token__first-step'>
                                    <p className="c-tutorial-token__text c-tutorial-token__text--subtitle" dangerouslySetInnerHTML={{ __html: _["TutorialSectionSubTitle001"] }} />
                                    <p className="c-tutorial-token__text" dangerouslySetInnerHTML={{ __html: _["TutorialSectionSubText001"] }} />
                                </div>
                            </div>

                            <div className="c-tutorial-token__block c-tutorial-token__block--first" >
                                <img className='c-tutorial-token__image c-tutorial-token__image--unicorn-steps' src={'/assets/images/tutorial-token/unicorn.svg'} alt="" />
                            </div>

                            <div className="c-tutorial-token__block c-tutorial-token__block--second text-center">
                                <AnimationOnScroll animateIn="animate__fadeInLeft" delay={0} animateOnce={true} >
                                    <img 
                                        className='c-tutorial-token__image c-tutorial-token__image--r1' 
                                        src={`/assets/images/steps/01-app-uniswap-${_['Actual'] === 'es' ? 'esp' : 'ing' }.svg`} 
                                        alt=""
                                    />
                                </AnimationOnScroll>
                            </div>

                            <div className="c-tutorial-token__block c-tutorial-token__block--third text-center text-lg-start">
                                <AnimationOnScroll animateIn="animate__fadeInRight" delay={0} animateOnce={true} >
                                    <img 
                                        className='c-tutorial-token__image c-tutorial-token__image--mar-top-40 c-tutorial-token__image--l15' 
                                        src={`/assets/images/steps/02-app-uniswap-${_['Actual'] === 'es' ? 'esp' : 'ing' }.svg`} 
                                        alt=""
                                    />
                                </AnimationOnScroll>
                            </div>

                            <div className="c-tutorial-token__block c-tutorial-token__block--fourth">
                                <img className='c-tutorial-token__image c-tutorial-token__image--donia' src={'/assets/images/tutorial-token/donia.svg'} alt="" />
                            </div>

                            <div className="c-tutorial-token__block c-tutorial-token__block--fourth">
                                <div className='c-tutorial-token__copy-content'>
                                    <p 
                                        className="c-tutorial-token__text c-tutorial-token__text--subtitle-tall c-tutorial-token__text--space-left" 
                                        dangerouslySetInnerHTML={{ __html: _["TutorialSectionSubTitle002"] }} 
                                    />

                                    <div className="c-tutorial-token__button-adress" onClick={copyClipboard}>
                                        {
                                            copy ? 
                                                <span>Copiado en el portapapeles</span>
                                                :
                                                <input
                                                    type="text"
                                                    disabled
                                                    className="c-steps__text-area"
                                                    value='0xe9f1d62c671efe99896492766c0b416bd3fb9e52'
                                                    id="adress"
                                                />
                                        }
                                    </div>

                                    <p 
                                        className="c-tutorial-token__text c-tutorial-token__text--space-left" 
                                        dangerouslySetInnerHTML={{ __html: _["TutorialSectionSubText002"] }} 
                                    />
                                </div>
                            </div>
                            
                            <div className="c-tutorial-token__block c-tutorial-token__block--first">
                                <p className="c-tutorial-token__text c-tutorial-token__text--l1" dangerouslySetInnerHTML={{ __html: _["TutorialSectionSubText003"] }} />
                            </div>

                            <div className="c-tutorial-token__block c-tutorial-token__block--first" />

                            <div className="c-tutorial-token__block c-tutorial-token__block--first text-center">
                                <AnimationOnScroll animateIn="animate__fadeInLeft" delay={0} animateOnce={true} >
                                    <img 
                                        className='c-tutorial-token__image c-tutorial-token__image--r1 c-tutorial-token__image--p-tb-box' 
                                        src={`/assets/images/steps/03-app-uniswap-${_['Actual'] === 'es' ? 'esp' : 'ing' }.svg`} 
                                        alt="" 
                                    />
                                </AnimationOnScroll>
                            </div>

                            <div className="c-tutorial-token__block c-tutorial-token__block--first" />

                            <div className="c-tutorial-token__block c-tutorial-token__block--first text-center">
                                <AnimationOnScroll animateIn="animate__fadeInLeft" delay={0} animateOnce={true} >
                                    <img 
                                        className='c-tutorial-token__image c-tutorial-token__image--r1 c-tutorial-token__image--p-tb-box' 
                                        src={`/assets/images/steps/05-app-uniswap-${_['Actual'] === 'es' ? 'esp' : 'ing' }.svg`} 
                                        alt="" 
                                    />
                                </AnimationOnScroll>
                            </div>

                            <AnimationOnScroll animateIn="animate__fadeInRight" delay={0} animateOnce={true} className="c-tutorial-token__block c-tutorial-token__block--first text-center" >
                                <img 
                                    className='c-tutorial-token__image c-tutorial-token__image--last-box c-tutorial-token__image--r1 c-tutorial-token__image--p-tb-box' 
                                    src={`/assets/images/steps/04-app-uniswap-${_['Actual'] === 'es' ? 'esp' : 'ing' }.svg`} 
                                    alt=""
                                />
                            </AnimationOnScroll>
                        </div>
                        <p 
                            className="c-tutorial-token__text text-center" 
                            dangerouslySetInnerHTML={{ __html: _["TutorialSectionSubText004"] }} 
                        />
                    </div>
                </div>
            </div>
        </section>
        <LastDiv />
    </>
}

export default TutorialToken;