import { useEffect, useState } from "react";

const UseWindowSize = () => {
    const [windowSize, setWindowSize] = useState(undefined);

    useEffect(() => {
        let box = document.body;
        let width = box.offsetWidth;
        setWindowSize(width)
        if (typeof window !== 'undefined') {
            function handleResize() {
                setWindowSize(window.innerWidth);
            }
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);

    return windowSize;
};

export default UseWindowSize;